@import url('https://fonts.googleapis.com/css2?family=Vollkorn&display=swap');

.body{
    position: relative;
    padding-top: 50px;
}
.body h2{
    font-size: 2.6em;
    padding-left: 20px;
    font-family: 'Vollkorn', serif;
    font-weight: 600;
    text-decoration: underline;
}
.link{
    position: relative;
    width: 30%;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    margin-left: 20px;
    background-color: #000;
    color: whitesmoke;
    font-family: 'Vollkorn', serif;
    padding: 20px;
    padding-top: 30px;
    cursor: pointer;
    z-index: 1;
}

.link:hover{
    background-color: #282828;
}
.link h3{
    font-size: 1.8em;
    font-weight: 100;
    text-decoration: underline;
    font-family: 'Vollkorn', serif;
}
.link p{
    font-weight: 100;
    font-family: 'Vollkorn', serif;
    padding-top: 5px;
    padding-bottom: 5px;
}
.stack .h2{
    font-weight: 1.6em;
    background-color: #1B1212;
}
.link h3{
    font-size: 2em;
    font-weight: 100;
    text-decoration: underline;
    font-family: 'Vollkorn', serif;
}
.link p{
    font-size: 1.2em;
    font-weight: 100;
    font-family: 'Vollkorn', serif;
    padding-top: 30px;
    padding-bottom: 5px;
}
.h2{
    font-weight: 1.6em;
    text-decoration: underline;
    font-weight: 100;
    font-family: 'Vollkorn', serif;
    padding-top: 20%;
    padding-bottom: 5px;
    background-color: #000;
}
.h2:hover{
    background-color: #1B1212;
}
.stack img{
    height: 3vh;
}
.button{
    color: whitesmoke;
    text-decoration: underline ;
    font-size: 1.2em;
    font-family: 'Vollkorn', serif;
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}
.popUp{
    width: 100%;
    display: flex;
    justify-content: center;
}
.a{
    margin: 10px;
    text-decoration: none;
    color: whitesmoke;
    /* background-color: rgb(3, 3, 98); */
    background-color: rgb(10, 86, 10);
    font-size: x-small;
    padding: 8px;
    border-radius: 5px;
    font-weight: bold;
    z-index: 5;
}
@media (max-width: 680px) {
    .link{
        width: 90%;
    }
    .link:hover{
        background-color: #000;
    }
    .body{
        padding-top: 18%;
    }
}

.link h3{
    font-size: 1.2em;
    font-weight: 100;
    text-decoration: underline;
    font-family: 'Vollkorn', serif;
}
.link p{
    font-weight: 100;
    font-size: 0.9em;
    font-family: 'Vollkorn', serif;
    padding-top: 5px;
    padding-bottom: 5px;
}