
@import url('https://fonts.googleapis.com/css2?family=Vollkorn&display=swap');
.footer{
    position: relative;
    display: flex;
    justify-content: space-around;
    background-color: #000;
    color: #fff;
    font-family: 'Vollkorn', serif;
    margin-top: 30px;
}
.footer h4{
    display: none;
}
.div1{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.div1 p{
    font-size: 3em;
    font-weight: 500;
    font-family: 'Vollkorn', serif;
    margin-bottom: 25%;
}
.div1 h3{
    margin-bottom: 20%;
}
.links{
    width: 20%;
}
.links h2{
    font-size: 2em;
    font-weight: 500;
    font-family: 'Vollkorn', serif;
    margin-bottom: 2%;
    padding-top: 10px;
}
.links ul{
    margin-left: 2%;
    list-style-type: none;
    margin-bottom: 2%;
}
ul .links a{
    text-decoration: none;
    list-style-type: none;
    color: white;
    font-size: 1.1em;
    font-family: 'Vollkorn', serif;
    transition: color 0.3s
}

ul .links a:hover{
    color: rgb(16, 16, 173);
    transition: width 0.3s ease-in-out;
    text-decoration: underline;
}
.links ul li{
    padding-bottom: 10px;
}
.socials h2{
    font-size: 2em;
    font-weight: 500;
    font-family: 'Vollkorn', serif;
    margin-bottom: 2%;
    padding-top: 10px;
}
.socials a img{
    height: 30px;
    margin-right: 10px;
}

@media(max-width:680px){
    .footer{
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: #000;
        
    }
    .div1{
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        height: 100%;
    }
    .div1 p{
        font-size: 1.7em;
        margin-bottom: 0;
    }
   .div1 h3{
    padding-top: 100px;
    font-size: small;
        
   }
    .links{
        margin-top: 5%;
        margin-left: 20%;
        padding-top: 0;
        font-weight: 1.7em;
    }
    .links h2{
        font-size: 2em;
        margin-bottom: 0;
        padding-top: 0;
        font-size: 17px;
    }
    .links ul{
        margin-bottom: 0;
    }
    .links ul li{
        padding-bottom: 0;
        font-size: 13px;
    }
    .socials{
        padding-bottom: 5%;
        padding-left: 10px;
    }
    .socials h2{
        padding-top: 0;
        font-size: 17px;
        margin-bottom: 0;
    }
    .socials a img{
        height: 3vh;
    }
    
}
@media (max-width:415px){
    .footer{
        display: flex;        
    }
    .div1{
        width: 70%;
        margin-top: 5%;
    }
    .div1 p{
        font-size: 1em;
    }
    .div1 h3{
        margin-top: 10px;
    }
    .links {
        width: 30%;
    }
    .links h2{
        text-decoration: underline;
    }
}
