.background{
}
.h1{
    position: relative;
    text-align: center;
    display: block;
    padding-top: 5%;
    margin-bottom: 10px;
    
    font-family: 'Vollkorn', serif;
    font-weight: normal;
}
.p{
    
    text-align: center;
    font-family: 'Vollkorn', serif;
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 10px;
}

.wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
}
.icon{
    position: relative;
    margin-left: 40%;
    margin-top: 20%;
    height: 50px;
    width: 50px;
    
}
.card{
    
    width: 320px;
    padding: 20px;
    display: block;
    flex-direction: column;
    font-family: 'Vollkorn', serif;
    font-weight: normal;
    
}
.card .p{
    font-size: 15px;
    text-align: start;
}
.h3{
    position: relative;
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
    margin-top: 7%;
    margin-bottom: 12px;
    font-weight: 400;
    
    font-family: 'Vollkorn', serif;
    
}
.text{
    position: relative;
    display: flex;
    padding: 100px;
    margin-left: 10%;
    font-family: 'Vollkorn', serif;
    font-weight: normal;
}
.image{
    position: relative;
    height: 45px;
    margin-left: 5%;
}
.stack{
    position: relative;
    display: flex;
    justify-content: center;
    width: 80%;
    text-align: center;
    padding-bottom: 5%;
    margin-left: 10%;
}
.card.p{
    font-family: 'Vollkorn', serif;
    font-weight: normal;
    color: white;
}
.stack h4{
    display: flex;
    align-items: center;
    font-family: 'Vollkorn', serif;
    font-weight: normal;
}
.iconResponsive{
    display: none;
}
@media ( max-width: 680px ){
  
    .h1{
       position: relative;
       display: block;
       padding-top: 18%;
       font-size: 30px;
    }
    .wrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        
    }
    .card{
        position: relative;
        width: 50%;
        border-radius: 5px;
        display: block;
        flex-direction: column;
    } 
    .h3{
        font-size: 13px;
        margin-right: 10px;
        font-weight: bold;
        font-size: 1.1em;
    }
   
    .icon{
        position: relative;
        margin-left: 40%;
        margin-top: 2%;
        height: 50px;
        width: 50px;
        background-color: white;
    }
    
    
    
    .stack{
        position: relative;
        display: flex;
        justify-content: center;
        width: 80%;
        text-align: center;
        padding-bottom: 30px;
        margin-left: 10%;
    }
    .stack h4{
        font-size: 15px;
    }
    .image{
        position: relative;
        height: 30px;
        margin-left: 5%;
    }
}
@media(max-width:415px){
    .h1{
        font-size: 20px;
        text-decoration: underline;
    }
    .p{
        font-size: 15px;
    }
    .card{
        width: 100%;
    }
    .icon{
        margin-top: 2px;
    }
}