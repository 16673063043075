
.body{
   position: relative;
   height: 100vh;
   width: 100%; 
   background-color: #fff;
   display: flex;
   justify-content: space-around;
}
.div1{
   width: 40%;
   margin-top: 13%;
}
.h2{
   position: relative;
   margin-top: 15%;
   font-size: 3em;
   font-family: 'Vollkorn', serif;
   font-weight: normal;
   
}
.div2{
   width: 50%;
   margin-top: 10%;
   margin-right: 2%;
}
.p{
   position: relative;
   line-height: 2;
   font-size: 1.4em;
   font-family: 'Vollkorn', serif;
   font-weight: normal;
   
}

@media ( max-width:680px ){
   .body{
      height: 100vh;
      display: flex;
      flex-direction: column;
      width: 100%; 
   }
 .body .h2{
   padding-top: 10%;
 }
   .div2{
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 0.7em;
      width: 100%;
   }
   .div2 .p{
      margin-left: 20px;
   }
}
@media(max-width:415px){

  
   /* .h2{
      padding-right: 20%;
      background-color: gray;
      font-size: 2em;
   } */
   .body .h2{
      padding-top: 20%;
      padding-left: 80px;
    }
    .div2{
      margin-bottom: 200px;
    }
}