
/* @import url('https://fonts.googleapis.com/css2?family=Vollkorn&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Vollkorn&display=swap');

.body{
    position: relative;
    display: flex;
    justify-content: center;
}
.div1{
    padding-top: 15%;
    padding-bottom: 10%;
    width: 50%;
    padding-left: 15%;
}
.div1 h2{
    font-size: 3.5em;
    font-weight: 500;
    font-family: 'Vollkorn', serif;
}
.div1 h3{
    font-size: 1.2em;
    font-weight: 500;
    font-family: 'Vollkorn', serif;
    margin-bottom: 5%;
}
.div1 h4{
    font-size: 1.2em;
    font-weight: 500;
    font-family: 'Vollkorn', serif;
}

.form{
    margin-top: 5%;
    margin-right: 2%;
    width: 50%;
    background-color: black;
    margin-bottom: 5%;
    border-radius: 10px;
}
.form h2{
    font-size: 2em;
    font-weight: 500;
    font-family: 'Vollkorn', serif;
    padding-left: 5%;
    margin-top: 5%;
    margin-bottom: 3%;
    color: white;
}
.inputDiv{
    display: flex;
    flex-direction: column;
}
.inputContainer{
    position: relative;

}
 .input{
    font-size: 17px;
    top: 0;
    left: 0;
    padding-top: 25px; /* Adjust this value to move the text closer to the bottom */
    font-family: 'Vollkorn', serif;
    width: 80%;
    border: none;
    border-radius: 5px;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    margin-left: 5%;
    background-color: black;
    outline: none;
    background: none;
    color: white;
}
.input:focus {
    border-bottom: 1px solid rgb(73, 234, 73);
}
.label{
    position: absolute;
    top: 25px;
    left: 5%;
    font-size: 14px;
    padding: 0px 5px;
    color: gray;
    transition: 0.3s;
    pointer-events: none;
    font-family: 'Vollkorn', serif;
}

.input:focus ~ .label,
.input:not(:placeholder-shown) ~ .label,
.input:not(:focus):not(:placeholder-shown) ~ .label {
    top: 4px;
    font-size: 10px;
    color: white;
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
}



/* .input:focus + .label{
    top: 4px;
    font-size: 10px;
    color: white; 
} */
.textarea{
    height: 150px;
    width: 80%;
    border: none;
    padding-top: 20px;
    font-size: 17px;
    border-radius: 5px;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    margin-left: 5%;
    background-color: black;
    color: white;
    outline: none;
    font-family: 'Vollkorn', serif;
}
.textarea:focus{
    border-bottom: 1px solid rgb(73, 234, 73);
}
.textarea:focus ~ .label,
.textarea:not(:placeholder-shown) ~ .label,
.textarea:not(:focus):not(:placeholder-shown) ~ .label {
    top: 4px;
    font-size: 10px;
    color: white;
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
}

.button{
    width: 15%;
    height: 4vh;
    border-radius: 10px;
    background-color: white;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-top: 2%;
    cursor: pointer;
    font-weight: 700;
}
.button:hover{
    color: darkgreen;
}
.error{
    color: rgba(240, 30, 11, 0.664);
    margin-left: 6%;
    font-size: 12px;
}
.links{
    display: flex;
}
.links img{
    height: 40px;
    margin-bottom: 10px;
}
.links p{
    line-height: 2;
    font-size: 1.2em;
    font-family: 'Vollkorn', serif;
    padding-left: 5px;
    cursor: pointer;
}
.links p:hover{
    color: blue;
}

.successMessage {
    position: absolute;
    top: 0;
    right: 0; /* Initially on-screen */
    width: 100%;
    height: 50px;
    background-color: #4caf50;
    color: white;
    text-align: center;
    line-height: 50px;
    transform: translateX(100%); /* Move off-screen using transform */
    transition: transform 0.3s ease-out; /* Slide animation effect */
  }
  
  .successMessage.show {
    transform: translateX(0); /* Slide in to be visible */
  }
@media (max-width:680px){
    .body{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 13%;
        
    }
    .div1{
        width: 100%;
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5%;
    }
    .text{
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .div1 h2{
        font-size: 2.1em;
        text-decoration: underline;
    }
    .div1 h4{
        font-size: 15px;
        align-items: start;
    }
    .div1 h3{
        font-size: 15px;
    }
    .form{
        width: 95%;
        margin: 2%;
    }
    .links img{
        height: 25px;
    }
    .links p{
        font-size: 15px;
    }    
}
@media (max-width: 415px){
    .button{
        font-size: 0.5em;
    }
}
