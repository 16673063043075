@import url('https://fonts.googleapis.com/css2?family=Vollkorn&display=swap');

.navbar{
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1000;
    border-bottom: #fff;
    border-bottom: 10px;
    background-color: #000;
    margin-top: 0;
    height: 12vh;
    color: white;
}

.logo{
    position: relative;
    margin-left: 5%;
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 30px;
    height: 10vh;
}
.logo a{
    text-decoration: none;
    color: white;
}
.ul{
    position: relative;
    display: flex;
    list-style-type: none;
    color: green;
    font-size: 1.2em;
    width: 30%;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    color: #fff;
    margin-top: 30px;
}
.a{
    position: relative;
    text-decoration: none;
    color: white;
    font-weight: bold;
    position: relative; /* make the element position relative */
    text-decoration: none;
    margin-top: 30px;
    font-family: 'Vollkorn', serif;
    font-weight: normal;
}

.a:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0;
    background-color: black;
    transition: width 0.3s ease-in-out; /* add smooth transition effect */
  }

.a:hover:before {
    width: 100%; /* expand the width to 100% on hover */
  }

.githubLink{
    margin-top: 7px;
    position: relative;
    transition: all 0.3s ease-in-out;
}
  
.githubLink:hover {
    transform: scale(1.1); /* scale the element up by 10% on hover */
  }

.connect{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8%;
    margin-top: 20px;
    background-color: white;
    color: black;
    border-radius: 10px;
    height: 4vh;
    width: 8%;
}

.letsConnect{
    color: #000;
    text-decoration: none;
    font-family: 'Vollkorn', serif;
    background-color: white;
}
.letsConnect:hover{
    color: maroon;
}
.hamburger{
    position: relative;
    display: none;
    margin-right: 10%;
}
.ulHamburger{
    display: none;
}

@media (max-width: 680px) {
    .navbar{
        display: flex;
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        
    }
    .connect{
        display: none;
    }
    .letsConnect{
        display: none;
    }
    .ul{
        display: none;
    }
   
   
    .links{
        display: block;
        background-color: #fff;
        border-bottom: 0.5px solid black;
        width: 100%;
    }
    .a{
        width: 100%;
        align-items: center;
    }
    .a:hover{
        background-color: grey;
        color: white;
    }
    .btn{
        display: none;
    }
    .hamburger{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 20px;
        background: transparent;
        border: none;
        cursor: pointer;
        margin: 4%;
        margin-right: 5%;
    }
    .hamburger span{
        display: block;
        width: 100%;
        height: 2px;
        background: #000;
        border-radius: 3px;
        transition: transform 0.3s ease-in-out;
        background-color: #fff;
    }
    .githubLink{
        margin-left: 50%;
    }
    .logo{
        margin-top: 5%;
    }
    .imgBox{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 20px;
    }
    .animation{
        font-size: 1px;
    }
    .githubLink{
        display: none;
    }
    .line{
        width: 60%;
        border-bottom-width: 1px;
        border-color: white;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .ulHamburger{
        position: absolute;
        display: flex;
        flex-direction: column;
        margin-top: 60%;
        width: 90%;
        margin-left: 5%;
        border: 1px;
        border-radius: 20px;
        color: black;
    }
    .ulHamburger .links  {
        padding-top: 20px;
        height: 8vh;
        padding-bottom: 20px;
    }
    .links .a{
        color: #000;
        height: 100%;
        width: 100%;
        padding-left: 5%;
        font-size: 0.8em;
    }
}
@media(max-width: 415px){
    .ulHamburger{
        margin-top: 80%;
    }
    .navbar .logo{
        margin-top: 12%;
    }
}

