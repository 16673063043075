@import url('https://fonts.googleapis.com/css2?family=Vollkorn&display=swap');
.banner{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin-right: 40%;
    background-color: #000;
}
.text{
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 8%;
    color: white;
    margin-top: 6%;
}
.text .h3{
    position: relative;
    top: 0;
    font-size: 1.6em;
    line-height: 1.4;
    font-family: 'Vollkorn', serif;
    font-weight: normal;
    color: white;
}
.text .h1{
    position: relative;
    justify-content: center;
    font-size: 60px;
    line-height: 1.6;
    font-weight: bold;
    overflow: hidden;
    font-family: 'Vollkorn', serif;
    color: white;
}
.animation{
    width: 200px; /* Set a fixed width */
    height: 50px; /* Set a fixed height */
    overflow: hidden;
    
}
.text .p{
    font-size: 1.4em;
    color: #000;
    line-height: 1.6;
    font-family: 'Vollkorn', serif;
    color: white;
    font-weight: normal;
}

.btn{
    position: relative;
    margin-right: 3%;
    margin-top: 10px;
    text-decoration: none;
    padding: 10px;
    background-color: white;
    color: black;
    width: 15%;
    text-align: center;
    font-weight: bold;
    border: 1px solid black;
    font-family: 'Vollkorn', serif;
    border-radius: 20px;
}
.buttons .btn{
    border-radius: 240px;
}

.btn:hover{
    color: maroon;
    cursor: pointer;
}
.letsConnect{
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.imgBox{
    position: relative;
    height: 65vh;
    width: 35%;
    border-radius: 15px;
    /* border: 1px solid gray; */
}

.img{
    /* chech here */
    position: relative; 
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 25px;
    width: 100%;
    
}
.tags{
    position: relative;
    width: 50%;
}
.a{
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #000;
    border: 2px solid black;
    padding: 10px 20px;
    border-radius: 5%;
    width: 30%;
    margin-top: 10%;
    font-weight: bold;
}
.a:hover{
    background-color: #000;
    color: #fff;
}
.a2{
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    background-color: #000;
    border: 2px solid black;
    padding: 10px 20px;
    border-radius: 5%;
    margin-left: 5%;
    width: 30%;
    margin-top: 10%;
    font-weight: bold;
}
.a2:hover{
    background-color: #fff;
    color: #000;
}
.animation{
    width: 200px; /* Set a fixed width */
    height: 50px; /* Set a fixed height */
    overflow: hidden;
}
.responsiveText{
    display: none;
}
.text .p1{
    display: none;
}
.buttons{
    display: flex;
}
.buttons a{
    border-radius: 15px;
}
.links{
    display: flex;
    margin-top: 3%;
}
.links .linkedinDiv{
    
    background-color: #fff;
}
.linkedinDiv a img{
    height: 100%;
}
.links .githubDiv{
    height: 70px;
    background-color: #fff;
}
.links a .img1{
    height: 30px;
    margin-left: 10px;
    background-color: white;
}
.links a .img{
    height: 30px;
    margin-left: 10px;
}
.flex{
    display: flex;
    width: 100%;
}

@media ( max-width: 680px ) {
    .banner{
        position: relative;
        padding-right: 10%;
        display: flex;
        flex-direction: column;
    }
    .h1 .animation{
        display: none;
    }

    .skills{
        background-color: #000;
        color: #fff;
        cursor: pointer;
    }
    .text{
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-top: 40%;
    }
    .skills:hover{
        color: #000;
        background-color: #fff;
        cursor: pointer;
    }
    .button{
        padding-left: 0;
    }
    .buttons a{
        font-size: 10px;
        position: relative;
        width: 12%;
        border-radius: 15px;
        height: 12%;
        display: flex;
        align-items: center;
        text-align: center;
    }
   
    .imgBox{
        position: relative;
        width: 100%;
        height: 30vh;
        animation: bounce 2s infinite;
        display: flex;
        justify-content: center;
        display: none;
    }
    .img{
        width: 50%;
    }
    .text .p{
        font-size: 15px;
        font-family:Georgia, 'Times New Roman', Times, serif;
    }
    
    .responsiveText{
        position: relative;
        font-weight: 800;
        display: inline-block;
        font-size: 30px;
        padding-top: 10px;
        padding-bottom: 10px ;
        font-family: 'Vollkorn', serif;
        
    }
    .text .p1{
        display: inline-block;
        font-size: 13px;
        font-family:Georgia, 'Times New Roman', Times, serif;
    }
    .h3{
        display: none;
    }
}
@media (max-width: 680px ){
    .text{
        margin-top: 60%;
    }
    .buttons .btn{
        width: 17%;
    }
    
}
@media (max-width: 415px){
    .h1 {
        font-size: 20px;
    }
}