.body{
    position: relative;
   
    width: 100%; 
    opacity: 50;
 }
 .popUp{
    position: absolute;
    border: 10px;
    border-radius: 20px;
    box-shadow: 30px;
    background-color: white;
    color: black;
    margin-bottom: 80%;
 }